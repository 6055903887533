import React from 'react';
import c from 'classnames';

import BannerImage from '../../static/img/banner.jpg';
import BannerMobileImage from '../../static/img/banner-mobile.jpg';
import LogoImage from '../../static/img/logo.svg';
import hours from '../../data/hours.json';

import styles from './content.module.css';

const DayHours = ([day, hours]) => (
  <tr key={day}>
    <td className="pl-0">{day}</td>
    <td>{hours}</td>
  </tr>
);

export default function WalkInContent() {
  return (
    <section className={c(styles.splash)}>
      <img src={BannerImage} className="d-none d-md-block" style={{ width: '100%' }} alt="Easton Place Medical Centre" />
      <img src={BannerMobileImage} className="d-block d-md-none" style={{ width: '100%' }} alt="Easton Place Medical Centre" />
      <div className={c(styles.splashContent, 'py-4', 'py-md-5')}>
        <div className="container">
          <div className="row">
            <div className="d-none d-md-block col-2">
              <img src={LogoImage} className="px-5 px-md-0" style={{ width: '100%' }} alt="Easton Place logo" />
            </div>
            <div className="col-12 col-md-10 pl-md-5">
              <p className="h3">
                Walk-in Clinic
              </p>
              <p>
                Located at the heart of our state-of-the-art facility, Easton Place Medical Clinic is dedicated to providing high-quality medical care with a personal touch. Our team of experienced and compassionate healthcare professionals is committed to guiding you on your path to wellness.
              </p>
              <p>
                Our clinic is pleased to offer <strong>same-day appointments</strong> for new patients.
              </p>
              <p>
                To minimize your wait time, please call ahead to make a booking: <a href="tel:2044824044">(204) 482-4044</a>
              </p>
              <p className="h5 mt-4">Contact</p>
              <p>
                Phone: <a href="tel:12044824044">204-482-4044</a>
                <br />
                Email:{' '}
                <a href="mailto:bookings@eastonplace.ca">
                  bookings@eastonplace.ca
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}